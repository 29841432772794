import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { setupMessageListener, messageMintSite } from './messages';

import 'semantic-ui-css/semantic.min.css';
import './index.css';

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// setupMessageListener();

// messageMintSite('configuratorInit')

/**
 * Example code for the `finalize` message
 */
// messageMintSite('finalize', {
//   walletAddress: <USER_WALLET_ADDRESS>,
//   tokenId: <TOKEN_ID_ARRAY>,
//   configuration: <CONFIGURATION_OBJECT>,
// })
