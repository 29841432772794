import KeepersABI from './Keepers_abi.json';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

const web3 = new Web3(
  'https://smart-spring-cherry.quiknode.pro/7e50b4a2e1b94b89c55c1469f0c8c6cdba0c836f/'
);

const KeepersAddress = '0x7eeb4746d7cF45B864550C9e540aaCdbF1B9884A';

export const Keepers = new web3.eth.Contract(
  KeepersABI.abi as AbiItem[],
  KeepersAddress
);
