import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';

import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
  onClose: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  // useEffect(() => {
  //   const start = () => {props.Launch()};
  //   start();
  // }, []);

  return (
    <div
      style={{
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '5%',
      }}
      id="launchContainer"
    >
      <div />
      <div>
        {/* <h1>{client.description}</h1> */}
        <Button
          className="playButtom"
          size="massive"
          color="blue"
          circular
          icon="play"
          onClick={() => props.Launch()}
        ></Button>
      </div>
      <button
        style={{
          border: 'none',
          background: 'black',
          color: 'lightgrey',
          fontFamily: "'Space Grotesk', sans-serif",
          fontWeight: 700,
        }}
        onClick={props.onClose}
      >
        CLOSE
      </button>
      {/* <img alt="PureWeb Logo" src="/pureweb.svg" style={{ width: 100, position: 'absolute', bottom: 50, right: 10 }} /> */}
    </div>
  );
};
