import React from 'react';
import './App.css';
import Overlay from './Overlay';
import Cart from './Cart';
import Traits from './GroundTruthTraits6.json';
import { csv, tickFormat } from 'd3'
import equal from 'fast-deep-equal'
import { areTraitsCompatible } from './TraitConflictHelpers';

const maxTraits: any = {
    head: 1,
    body: 1,
    upper: 2,
    lower: 1,
    shoes: 1,
    suit: 2,
    accessories: 4
}

class RightSideUI extends React.Component<any,any> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedItemId: '',
            selectedItemType: '',
            selectedItemTitle: '',
            selectedItemEdition: '',
            selectedItemTotalSupply: '',
            selectedItemPrice: '',
            selectedItemDisplay: 'none',
            selectedItemOpacity: '0',
            cartDisplay: 'none',

            filterValue: ['All'],
            filterTypeValue: ['All'],
            overlayAssetName: '',

            leftSortDisplay: 'none',
            centerSortDisplay: 'none',
            rightSortDisplay: 'none',
            leftSortValue: 'none',
            centerSortValue: 'none',
            rightSortValue: 'none',
            grid: 'large',
            cartObj: {
                head: [],
                body: [],
                upper: [],
                lower: [],
                shoes: [],
                suit: [],
                accessories: []
            },
            cartTotal: 0,
            traits: Traits
        };
    }

    setAvailabilityAndPrices = () => {
        this.setState( (prevState: any) => {
            let newTraits = prevState.traits.map( (e: any) => {
                return {
                    ...e, 
                    remainingSupply: this.props.availability[parseInt(e.ID)],
                    Price: this.props.allPrices[e.ID]
                }
            });
            console.log("Availability Set");
            return {
                traits: newTraits
            }
        })
    }

    componentDidMount(): void {
        // this.props.selectItem(`selectitem 9`);
        // Traits.forEach((trait) => {
        //     let img = new Image(500, 500);
        //     img.src = `https://cdn.keepers.com/${trait.AssetName}.webp`;
        //     let video = document.createElement('video');
        //     video.src = `https://cdn.keepers.com/${trait.AssetName}.webm`;
        // });
        
        this.setState( (prevState: any) => {
            let newTraits = prevState.traits.map( (e: any) => {
                return {...e, Active: false}
            });
            console.log(newTraits);
            return {
                traits: newTraits
            }
        });
        setTimeout(()=>{
            this.setAvailabilityAndPrices();
        }, 3000);
        const THIRTY_SECONDS = 31000;
        const interval = setInterval(() => {
            this.setAvailabilityAndPrices();
        }, THIRTY_SECONDS);

        document.getElementById("filter-button-below")?.addEventListener('mouseover', ()=> {
            let block = document.getElementById("nav_popup_below");
            if ( block != null) {
                block.style.display = "block"
            }
        });
        document.getElementById("filter-button-below")?.addEventListener('mouseout', ()=> {
            let block = document.getElementById("nav_popup_below");
            if ( block != null) {
                block.style.display = "none"
            }
        });
        document.getElementById("filter-button-surface")?.addEventListener('mouseover', ()=> {
            let block = document.getElementById("nav_popup_surface");
            if ( block != null) {
                block.style.display = "block"
            }
        });
        document.getElementById("filter-button-surface")?.addEventListener('mouseout', ()=> {
            let block = document.getElementById("nav_popup_surface");
            if ( block != null) {
                block.style.display = "none"
            }
        });
        document.getElementById("filter-button-above")?.addEventListener('mouseover', ()=> {
            let block = document.getElementById("nav_popup_above");
            if ( block != null) {
                block.style.display = "block"
            }
        });
        document.getElementById("filter-button-above")?.addEventListener('mouseout', ()=> {
            let block = document.getElementById("nav_popup_above");
            if ( block != null) {
                block.style.display = "none"
            }
        });
    }


    filterByConflicts = () => {
        let newTraits = this.state.traits.map((trait: any) => {
            trait.Conflict = '1';
            return trait;
        });

        Object.values(this.state.cartObj).forEach((array: any)=> {
            console.log(array);
            array.forEach((cartItem: any) => {
                if(cartItem) {
                    newTraits.forEach((trait: any)=> {
                        const isCompatible = areTraitsCompatible(this.props.allConflicts, cartItem.ID, trait.ID);

                        if(trait.Conflict !== '0'){
                            trait.Conflict = isCompatible ? '1' : '0';
                        }
                    });
                }
            });
        });

        this.setState({
            traits: newTraits
        })
    }
    isInt = (n: any) => {
        return n % 1 === 0;
    }

    setCartTotal = () => {
        let obj = this.state.cartObj;
        let headTotal = obj.head.reduce((total: any, current: any)=> {return total + current.Price},0);
        let bodyTotal = obj.body.reduce((total: any, current: any)=> {return total + current.Price},0);
        let upperTotal = obj.upper.reduce((total: any, current: any)=> {return total + current.Price},0);
        let lowerTotal = obj.lower.reduce((total: any, current: any)=> {return total + current.Price},0);
        let shoesTotal = obj.shoes.reduce((total: any, current: any)=> {return total + current.Price},0);
        let suitTotal = obj.suit.reduce((total: any, current: any)=> {return total + current.Price},0);
        let accessoriesTotal = obj.accessories.reduce((total: any, current: any)=> {return total + current.Price}, 0);
        let total = headTotal + bodyTotal + upperTotal + lowerTotal + shoesTotal + suitTotal + accessoriesTotal;
        console.log(total);
        if(this.isInt(total)){
            this.setState({
                cartTotal: parseInt(total)
            });
        } else {
            this.setState({
                cartTotal: parseFloat(total).toFixed(2)
            });
        }
    }

    chooseCartFunc = (data: any) => {
        console.log(data);
        console.log(data.Active);
        if(data.Active === false){
            // this.setState((prevState: any) => {
            //     let objIndex = prevState.traits.findIndex(((obj: any) => obj.ID == data.ID));
            //     console.log(objIndex);
            //     let newTraits = prevState.traits;
            //     newTraits[objIndex].Active = true;
            //     console.log(newTraits[objIndex].Active);
            //     return {
            //         traits: newTraits
            //     }
            // })
            this.addItemtoCart(data);
        } else {
            // this.setState((prevState: any) => {
            //     let objIndex = prevState.traits.findIndex(((obj: any) => obj.ID == data.ID));
            //     let newTraits = prevState.traits;
            //     newTraits[objIndex].Active = false;
            //     return {
            //         traits: newTraits
            //     }
            // })
            this.removeCartItem(data);
        }
    }

    addItemtoCart = (data: any) => {
        this.setState((prevState: any) =>{
            let traitObj = {
                ID: data.ID, 
                Price: parseFloat(data.Price), 
                AssetName: data.AssetName, 
                HumanReadableName: data.HumanReadableName, 
                Type: data.Type
            };
            let obj = prevState.cartObj;
            let newObj = prevState.cartObj;

            let objIndex = prevState.traits.findIndex(((obj: any) => obj.ID == data.ID));
            let newTraits = prevState.traits;

            if(obj[`${data.Type.toLowerCase()}`].length < maxTraits[`${data.Type.toLowerCase()}`]){
                // this.props.selectItem(`SelectItem ${data.AssetName}`);
                this.props.selectItem(`selectitem ${data.ID}`);
                newObj[`${data.Type.toLowerCase()}`] = obj[`${data.Type.toLowerCase()}`].filter((el: any) => {
                    return el.ID !== data.ID
                });
                newObj[`${data.Type.toLowerCase()}`].push(traitObj);
                newTraits[objIndex].Active = true;
            } else {
                alert(`Max ${data.Type} in cart. Please remove one and try again.`);
            }

            return{
                ...prevState,
                cartObj: newObj,
                traits: newTraits
            }
        }, () => {
            this.setCartTotal();
            this.filterByConflicts();
        });
    }

    removeCartItem = (data: any) => {
        this.setState((prevState: any) =>{
            let obj = prevState.cartObj;
            let newObj = prevState.cartObj;
            newObj[`${data.Type.toLowerCase()}`] = obj[`${data.Type.toLowerCase()}`].filter(( obj: any ) => {
                return obj.ID !== data.ID;
            });

            let objIndex = prevState.traits.findIndex(((obj: any) => obj.ID == data.ID));
            let newTraits = prevState.traits;
            newTraits[objIndex].Active = false;
            this.props.selectItem(`unselectitem ${data.ID}`);
            return{
                ...prevState,
                cartObj: newObj,
                traits:newTraits
            }
        }, () => {
            let bool = false;
            let bodyArr: any = Object.values(this.state.cartObj)[1];
            if(bodyArr.length === 0){
                this.props.selectItem(`selectitem ${this.props.baseBodyID}`);
            }
            Object.values(this.state.cartObj).forEach((e:any)=> {
                if(e.length !== 0){
                    bool = true;
                }
            });
            if(bool === false) {
                this.setState({
                    cartDisplay: 'none'
                })
            }
            this.setCartTotal();
            this.filterByConflicts();
        })
    }

    viewCart = () => {
        let d = this.state.cartDisplay === 'none' ? 'block': 'none';
        this.setState({
            cartDisplay: d
        })
    }

    viewLeftSort = () => {
        let d = this.state.leftSortDisplay === 'none' ? 'flex': 'none';
        this.setState({
            leftSortDisplay: d
        })
    }

    chooseTypeFilter = (element: any) => {
        let value = element.target.getAttribute('data-value');
        if(element.target.classList.contains('active')) {
            this.setState((prevState: any) => {
                let arr = prevState.filterTypeValue;
                console.log(arr.indexOf(value));
                arr.splice(arr.indexOf(value),1);
                if(arr.length === 0) {
                    arr.push('All');
                }
                console.log(arr);
                return {
                    ...prevState,
                    filterTypeValue: arr
                }
            }, ()=> {
                document.querySelectorAll('.filter-button.type').forEach(e => {
                    e.classList.remove('active');
                    if(this.state.filterTypeValue.includes(e.getAttribute('data-value'))) {
                        e.classList.add('active');
                    }
                });
            })
        } else {
            if(value === 'All'){
                this.setState({
                    filterTypeValue: [`${value}`]
                }, ()=> {
                    document.querySelectorAll('.filter-button.type').forEach(e => {
                        e.classList.remove('active');
                        if(this.state.filterTypeValue.includes(e.getAttribute('data-value'))) {
                            e.classList.add('active');
                        }
                    });
                })
            } else {
                this.setState((prevState: any) => {
                    let arr = prevState.filterTypeValue;
                    console.log(arr);
                    console.log(arr.indexOf('All'));
                    if(arr.indexOf('All') !== -1) {
                        arr.splice(arr.indexOf('All'),1);
                        console.log(arr);
                    }
                    arr.push(value);
                    console.log(arr);
                    return {
                        ...prevState,
                        filterTypeValue: arr
                    }
                }, ()=> {
                    document.querySelectorAll('.filter-button.type').forEach(e => {
                        e.classList.remove('active');
                        if(this.state.filterTypeValue.includes(e.getAttribute('data-value'))) {
                            e.classList.add('active');
                        }
                    });
                })
            }
        }
        // this.setState({
        //     filterTypeValue: `${element.target.getAttribute('data-value')}`
        // }, ()=> {
        //     document.querySelectorAll('.filter-button.type').forEach(e => {
        //         e.classList.remove('active');
        //         if(e.getAttribute('data-value') === this.state.filterTypeValue) {
        //             e.classList.add('active');
        //         }
        //     });
        // })
    }
    chooseFilter = (element: any) => {
        let value = element.target.getAttribute('data-value');
        if(element.target.classList.contains('active')) {
            this.setState((prevState: any) => {
                let arr = prevState.filterValue;
                console.log(arr.indexOf(value));
                arr.splice(arr.indexOf(value),1);
                if(arr.length === 0) {
                    arr.push('All');
                }
                console.log(arr);
                return {
                    ...prevState,
                    filterValue: arr
                }
            }, ()=> {
                document.querySelectorAll('.filter-button.part').forEach(e => {
                    e.classList.remove('active');
                    if(this.state.filterValue.includes(e.getAttribute('data-value'))) {
                        e.classList.add('active');
                    }
                });
            })
        } else {
            if(value === 'All'){
                this.setState({
                    filterValue: [`${value}`]
                }, ()=> {
                    document.querySelectorAll('.filter-button.part').forEach(e => {
                        e.classList.remove('active');
                        if(this.state.filterValue.includes(e.getAttribute('data-value'))) {
                            e.classList.add('active');
                        }
                    });
                })
            } else {
                this.setState((prevState: any) => {
                    // let arr = prevState.filterValue;
                    // console.log(arr);
                    // console.log(arr.indexOf('All'));
                    // if(arr.indexOf('All') !== -1) {
                    //     arr.splice(arr.indexOf('All'),1);
                    //     console.log(arr);
                    // }
                    // arr.push(value);
                    // console.log(arr);

                    let arr = [value];
                    return {
                        ...prevState,
                        filterValue: arr
                    }
                }, ()=> {
                    document.querySelectorAll('.filter-button.part').forEach(e => {
                        e.classList.remove('active');
                        if(this.state.filterValue.includes(e.getAttribute('data-value'))) {
                            e.classList.add('active');
                        }
                    });
                })
            }
        }
        // this.setState({
        //     filterValue: `${element.target.getAttribute('data-value')}`
        // }, ()=> {
        //     document.querySelectorAll('.filter-button.part').forEach(e => {
        //         e.classList.remove('active');
        //         if(e.getAttribute('data-value') === this.state.filterValue) {
        //             e.classList.add('active');
        //         }
        //     });
        // })
    }

    chooseLeftSort = (element: any) => {
        Array.from(document.querySelectorAll('.sortCheckMark.left')as NodeListOf<HTMLElement>).forEach(e => {
            e.style.opacity = '0';
        });
        element.target.firstElementChild.style.opacity = '1';
        this.setState({
            leftSortValue: `${element.target.getAttribute('data-value')}`
        })
        this.viewLeftSort();
    }

    gridClick = () => {
        if(this.state.grid === 'large'){
            this.smallGridClick();
        } else {
            this.largeGridClick();
        }
    }

    smallGridClick = () => {
        let scrollBoxArr = Array.from(document.getElementsByClassName('scroll_box') as HTMLCollectionOf<HTMLElement>)!;

        // let editionBoxArr = Array.from(document.getElementsByClassName('edition') as HTMLCollectionOf<HTMLElement>)!;

        let itemTitleArr = Array.from(document.getElementsByClassName('item_title') as HTMLCollectionOf<HTMLElement>)!;

        // let priceWrapperArr = Array.from(document.getElementsByClassName('price_wrapper') as HTMLCollectionOf<HTMLElement>)!;

        let itemImageArr = Array.from(document.getElementsByClassName('item_image') as HTMLCollectionOf<HTMLElement>)!;
        

        scrollBoxArr.forEach(e => {
            e.className = 'scroll_box scroll_box_large_grid';
        });
        // editionBoxArr.forEach(e => {
        //     e.style.opacity = '0';
        // });
        itemTitleArr.forEach(e => {
            e.style.display = 'none';
        });
        // priceWrapperArr.forEach(e => {
        //     e.style.display = 'none';
        // });
        itemImageArr.forEach(e => {
            e.style.paddingTop = '20px';
            e.style.paddingBottom = '20px';
        });
        this.setState({
            grid: "small"
        })
    }

    largeGridClick = () => {
        let scrollBoxArr = Array.from(document.getElementsByClassName('scroll_box') as HTMLCollectionOf<HTMLElement>)!;

        let editionBoxArr = Array.from(document.getElementsByClassName('edition') as HTMLCollectionOf<HTMLElement>)!;

        let itemTitleArr = Array.from(document.getElementsByClassName('item_title') as HTMLCollectionOf<HTMLElement>)!;

        let priceWrapperArr = Array.from(document.getElementsByClassName('price_wrapper') as HTMLCollectionOf<HTMLElement>)!;

        let itemImageArr = Array.from(document.getElementsByClassName('item_image') as HTMLCollectionOf<HTMLElement>)!;

        scrollBoxArr.forEach(e => {
            e.className = 'scroll_box scroll_box_small_grid';
        });
        editionBoxArr.forEach(e => {
            e.style.opacity = '1';
        });
        itemTitleArr.forEach(e => {
            e.style.display = 'block';
        });
        priceWrapperArr.forEach(e => {
            e.style.display = 'flex';
        });
        itemImageArr.forEach(e => {
            e.style.paddingTop = '5px';
            e.style.paddingBottom = '0px';
        });
        this.setState({
            grid: "large"
        })
    }

    tabClick = (e: any) => {
        this.setState({
            leftSortDisplay: 'none',
            centerSortDisplay: 'none',
            rightSortDisplay: 'none'
        })

        let leftTab = document.querySelector<HTMLElement>(".left_tab")!;
        let centerTab = document.querySelector<HTMLElement>(".center_tab")!;
        let rightTab = document.querySelector<HTMLElement>(".right_tab")!;

        let leftWrapper = document.querySelector<HTMLElement>(".wrapper_left")!;
        let centerWrapper = document.querySelector<HTMLElement>(".wrapper_center")!;
        let rightWrapper = document.querySelector<HTMLElement>(".wrapper_right")!;

        let leftTotal = document.querySelector<HTMLElement>(".left_tab_header_total")!;
        let centerTotal = document.querySelector<HTMLElement>(".center_tab_header_total")!;
        let rightTotal = document.querySelector<HTMLElement>(".right_tab_header_total")!;

        leftTotal.style.color = "white";
        centerTotal.style.color = "white";
        rightTotal.style.color = "white";

        if(e.target.className === "left_tab_header") {
            leftTab.style.zIndex = '3';
            centerTab.style.zIndex = '2';
            rightTab.style.zIndex = '1';
    
            leftWrapper.style.opacity = '1';
            centerWrapper.style.opacity = '0';
            rightWrapper.style.opacity = '0';

            leftTotal.style.color = "#006AFF"
        }
        if(e.target.className === "center_tab_header") {
            leftTab.style.zIndex = '2';
            centerTab.style.zIndex = '3';
            rightTab.style.zIndex = '1';
    
            leftWrapper.style.opacity = '0';
            centerWrapper.style.opacity = '1';
            rightWrapper.style.opacity = '0';

            centerTotal.style.color = "#006AFF"
        }
        if(e.target.className === "right_tab_header") {
            leftTab.style.zIndex = '1';
            centerTab.style.zIndex = '2';
            rightTab.style.zIndex = '3';
    
            leftWrapper.style.opacity = '0';
            centerWrapper.style.opacity = '0';
            rightWrapper.style.opacity = '1';

            rightTotal.style.color = "#006AFF";
        }
    }

    viewItem = (e: any) => {
        let parent = e.target.parentElement.parentElement.parentElement;
        let type, id, edition, title, price, asset_name, total = '';
    
        if(this.state.cartDisplay === 'block') {
            this.viewCart();
        }
        if(this.state.selectedItemDisplay === 'none') {
            edition = parent.querySelector('.edition')!.getAttribute('data-remaining');
            total = parent.querySelector('.edition')!.getAttribute('data-total');
            title = parent.querySelector('.item_title')!.innerHTML;
            price = parent.querySelector('.eth_price')!.innerHTML;
            asset_name = parent.querySelector('.item_title').getAttribute('data-value');
            id = parent.querySelector('.item_title').getAttribute('data-id');
            type = parent.querySelector('.item_title').getAttribute('data-type');
            console.log(asset_name);
            console.log(parent.querySelector('.item_title'));
        }
    
        if (this.state.selectedItemDisplay === 'none'){
            this.setState({
                selectedItemId: id,
                selectedItemType: type,
                selectedItemTitle: title,
                selectedItemEdition: edition,
                selectedItemTotalSupply: total,
                selectedItemPrice: price,
                selectedItemDisplay: 'block',
                overlayAssetName: asset_name
            })
            setTimeout(()=> {
                this.setState({
                    selectedItemOpacity: '1'
                })
            },50)
        } else if(this.state.selectedItemDisplay === 'block') {
            this.setState({
                selectedItemOpacity: '1'
            })
            setTimeout(()=> {
                this.setState({
                    selectedItemDisplay: 'none'
                })
            },50)
        }
    }


    render() {
        return(
            <div className='tabsWrapper' style={{display: this.props.loadingStateDisplay}}>
                <div className="tabs">
                    {/* <div className="nav_wrapper">
                        <div role="button"  onClick={(e) => this.tabClick(e)} onKeyDown={(e) => this.tabClick(e)} className="left_tab_header">
                            <h2 className="no-pointer-events">Below</h2>
                            <div className="left_tab_header_total no-pointer-events">
                                <span>
                                    {`${Traits.filter(trait => {return trait.Level === "Below" }).filter((trait)=> {
                                        if(this.state.leftFilterValue === 'All'){
                                            return trait;
                                        } else {
                                            return trait.Type === this.state.leftFilterValue
                                        }
                                    }).length}`}
                                </span>
                            </div>
                        </div>
                        <div role="button"  onClick={(e) => this.tabClick(e)} onKeyDown={(e) => this.tabClick(e)}  className="center_tab_header">
                            <h2 className="no-pointer-events">Surface</h2>
                            <div className="center_tab_header_total">
                                <span>
                                    {`${Traits.filter(trait => {return trait.Level === "Surface" }).filter((trait)=> {
                                        if(this.state.centerFilterValue === 'All'){
                                            return trait;
                                        } else {
                                            return trait.Type === this.state.centerFilterValue
                                        }
                                    }).length}`}
                                </span>
                            </div>
                        </div>
                        <div role="button"  onClick={(e) => this.tabClick(e)} onKeyDown={(e) => this.tabClick(e)}  className="right_tab_header">
                            <h2 className="no-pointer-events">Above</h2>
                            <div className="right_tab_header_total">
                                <span>
                                    {`${Traits.filter(trait => {return trait.Level === "Above" }).filter((trait)=> {
                                        if(this.state.rightFilterValue === 'All'){
                                            return trait;
                                        } else {
                                            return trait.Type === this.state.rightFilterValue
                                        }
                                    }).length}`}
                                </span>
                            </div>
                        </div>
                        <div id='nav_popup_below' className='nav_popup below'>
                            <h4>Below</h4>
                            <p>Below focuses on the diverse and extreme environments that served as the crucible of life. These harsh beginnings inspired a wealth of creation. Although we emerged from within, these environments remain filled with mysteries yet to be explored.</p>
                        </div>
                        <div id='nav_popup_surface' className='nav_popup surface'>
                            <h4>Surface</h4>
                            <p>Under the sun's direct gaze, surface represents the vibrant ecosystems where we learned to refine and apply our creativity on the elements to consciously thrive and adapt. From vast grasslands to dense forests, icy tundra to arid deserts, these landscapes have shaped our collective journey just as we have grown to largely shape them.</p>
                        </div>
                        <div id='nav_popup_above' className='nav_popup above'>
                            <h4>Above</h4>
                            <p>Above features elements from our atmosphere into the immeasurable cosmos. Our observations from a distance have shaped our cultures, science, and dreams. As we continue to stretch our reach into this frontier, the heightened exploration will further refine our perspective and ingenuity beyond current understanding.</p>
                        </div>
                    </div> */}
                    <div className="left_tab">
                        <div className="wrapper_left">
                            <div className="item_menu">
                                <div>
                                    <div className='filter-row _1'>
                                        <button onClick={(e)=> this.chooseTypeFilter(e)} data-value='All' className='filter-button type active'>
                                            All
                                            <span className='quantity_span'>
                                                {`${Traits.filter((trait: any)=> {
                                                    if(this.props.baseBodyID === 10){
                                                        return trait.Type !== 'Body'
                                                    } else {
                                                        return trait;
                                                    }
                                                }).filter((trait: any)=> {
                                                if(this.state.filterValue.includes('All')){
                                                    return trait;
                                                } else {
                                                    return this.state.filterValue.includes(trait.Type);
                                                }
                                            }).length}`}
                                            </span>
                                        </button>
                                        <button id='filter-button-below' onClick={(e)=> this.chooseTypeFilter(e)} data-value='Below' className='filter-button type below'>
                                            Below
                                            <span className='quantity_span'>
                                                {`${Traits.filter((trait: any)=> {
                                                    if(this.props.baseBodyID === 10){
                                                        return trait.Type !== 'Body'
                                                    } else {
                                                        return trait;
                                                    }
                                                }).filter(trait => {return trait.Level === "Below" }).filter((trait: any)=> {
                                                if(this.state.filterValue.includes('All')){
                                                    return trait;
                                                } else {
                                                    return this.state.filterValue.includes(trait.Type);
                                                }
                                                }).length}`}
                                            </span>
                                        </button>
                                        <button id='filter-button-surface' onClick={(e)=> this.chooseTypeFilter(e)} data-value='Surface' className='filter-button type surface'>
                                            Surface
                                            <span className='quantity_span'>
                                                {`${Traits.filter((trait: any)=> {
                                                    if(this.props.baseBodyID === 10){
                                                        return trait.Type !== 'Body'
                                                    } else {
                                                        return trait;
                                                    }
                                                }).filter(trait => {return trait.Level === "Surface" }).filter((trait: any)=> {
                                                if(this.state.filterValue.includes('All')){
                                                    return trait;
                                                } else {
                                                    return this.state.filterValue.includes(trait.Type);
                                                }
                                                }).length}`}
                                            </span>
                                        </button>
                                        <button id='filter-button-above' onClick={(e)=> this.chooseTypeFilter(e)} data-value='Above' className='filter-button type above'>
                                            Above
                                            <span className='quantity_span'>
                                                {`${Traits.filter((trait: any)=> {
                                                    if(this.props.baseBodyID === 10){
                                                        return trait.Type !== 'Body'
                                                    } else {
                                                        return trait;
                                                    }
                                                }).filter(trait => {return trait.Level === "Above" }).filter((trait: any)=> {
                                                if(this.state.filterValue.includes('All')){
                                                    return trait;
                                                } else {
                                                    return this.state.filterValue.includes(trait.Type);
                                                }
                                                }).length}`}
                                            </span>
                                        </button>
                                    </div>
                                    <div className='filter-row _2'>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='All' className='filter-button part active'>All</button>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Body' className='filter-button part'>Body</button>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Head' className='filter-button part'>Head</button>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Shoes' className='filter-button part'>Shoes</button>
                                    </div>
                                    <div className='filter-row'>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Upper' className='filter-button part'>Upper</button>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Lower' className='filter-button part'>Lower</button>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Suit' className='filter-button part'>Suit</button>
                                        <button onClick={(e)=> this.chooseFilter(e)} data-value='Accessories' className='filter-button part'>Accessories</button>
                                    </div>
                                </div>
                                {/* <button onClick={()=> this.viewLeftFilter()}className="k_btn k_btn_styled">
                                    <img alt=''  className="menu_icon" src="/images/filter_icon.png"/>
                                    Filter
                                </button> */}
                                <div className="item_sub_menu new_sub_menu">
                                    <button onClick={()=> this.viewLeftSort()} style={{marginRight: '10px'}} className="k_btn k_btn_styled">
                                        <img alt=''  className="menu_icon" src="/images/sortby_icon.png"/>
                                        Sort
                                    </button>
                                    <div className="k_btn_styled_2">
                                        {/* <button onClick={(e) => this.smallGridClick(e)} onKeyDown={() => this.smallGridClick()}  style={{marginRight: '5px'}} className="k_btn">
                                            <img alt=''  className="menu_sub_icon" src="/images/small_grid_icon.png"/>
                                        </button>
                                        <button onClick={(e) => this.largeGridClick()} onKeyDown={(e) => this.largeGridClick()} className="k_btn">
                                            <img alt=''  className="menu_sub_icon" src="/images/large_grid_icon.png"/>
                                        </button> */}
                                        <button onClick={(e) => this.gridClick()} onKeyDown={(e) => this.gridClick()} className="k_btn grid_icon_btn">
                                            <img alt=''  className="menu_sub_icon" src="/images/large_grid_icon.png"/>
                                        </button>
                                    </div>
                                    <div className='sort-popup' style={{display: `${this.state.leftSortDisplay}`}}>
                                        <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Descending'>Highest Price
                                            <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                        </button>
                                        <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Ascending'>Lowest Price
                                            <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                        </button>
                                        <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Compatible'>Compatible
                                            <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                        </button>
                                        <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Available'>Available
                                            <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                        </button>
                                        <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Selected'>Selected
                                            <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                        </button>
                                    </div>
                                </div>
                                {/* <div className='sort-popup' style={{display: `${this.state.leftSortDisplay}`}}>
                                    <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Descending'>Highest Price
                                        <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                    </button>
                                    <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Ascending'>Lowest Price
                                        <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                    </button>
                                    <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Compatible'>Compatible
                                        <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                    </button>
                                    <button onClick={(e)=> this.chooseLeftSort(e)} className='sort-button' data-value='Available'>Available
                                        <img className='sortCheckMark left' src='/images/check_mark.png'/>
                                    </button>
                                </div> */}
                            </div>
                            <div id="leftTab_scrollBox" className="scroll_box scroll_box_small_grid">
                                <div id='nav_popup_below' className='nav_popup below'>
                                    <div className='nav_popup_overlay'>
                                        <h4>Below</h4>
                                        <p>Below focuses on the diverse and extreme environments that served as the crucible of life. These harsh beginnings inspired a wealth of creation. Although we emerged from within, these environments remain filled with mysteries yet to be explored.</p>
                                    </div>
                                </div>
                                <div id='nav_popup_surface' className='nav_popup surface'>
                                    <div className='nav_popup_overlay'>
                                        <h4>Surface</h4>
                                        <p>Under the sun's direct gaze, surface represents the vibrant ecosystems where we learned to refine and apply our creativity on the elements to consciously thrive and adapt. From vast grasslands to dense forests, icy tundra to arid deserts, these landscapes have shaped our collective journey just as we have grown to largely shape them.</p>
                                    </div>
                                </div>
                                <div id='nav_popup_above' className='nav_popup above'>
                                    <div className='nav_popup_overlay'>
                                        <h4>Above</h4>
                                        <p>Above features elements from our atmosphere into the immeasurable cosmos. Our observations from a distance have shaped our cultures, science, and dreams. As we continue to stretch our reach into this frontier, the heightened exploration will further refine our perspective and ingenuity beyond current understanding.</p>
                                    </div>
                                </div>
                                {this.state.traits.filter((trait: any) => {
                                    if(this.state.filterTypeValue.includes('All')){
                                        return trait;
                                    } else {
                                        return this.state.filterTypeValue.includes(trait.Level);
                                    }
                                })
                                .filter((trait: any)=> {
                                    if(this.props.baseBodyID === 10){
                                        return trait.Type !== 'Body'
                                    } else {
                                        return trait;
                                    }
                                })
                                .filter((trait: any)=> {
                                    if(this.state.filterValue.includes('All')){
                                        return trait;
                                    } else {
                                        return this.state.filterValue.includes(trait.Type);
                                    }
                                }).filter((trait: any)=> {
                                    if(this.state.leftSortValue === 'Selected'){
                                        return trait.Active === true;
                                    } else {
                                        return trait;
                                    }
                                })
                                .sort((a:any, b:any) => {
                                    if(this.state.leftSortValue === 'Ascending') {
                                        return parseFloat(a.Price) - parseFloat(b.Price)
                                    } else if(this.state.leftSortValue === 'Descending') {
                                        return parseFloat(b.Price) - parseFloat(a.Price)
                                    } else if(this.state.leftSortValue === 'Compatible') {
                                        return parseInt(b.Conflict) - parseInt(a.Conflict)
                                    } else if(this.state.leftSortValue === 'Available') {
                                        return parseFloat(b.remainingSupply) - parseFloat(a.remainingSupply)
                                    } else {
                                        return 0;
                                    }
                                })
                                .map((trait: any, i: any) => {
                                    let overlayStyle: any, style: any, imageStyle: any, textStyle: any;
                                    if(trait.Conflict === '0'){
                                        textStyle = { opacity: '0.5'};
                                        imageStyle = {filter: 'grayscale(100%)',opacity: '0.5'};
                                        overlayStyle = {};
                                        style = {
                                            pointerEvents: 'none',
                                            backgroundColor: 'rgba(255, 255, 255, 0.01)'
                                        };
                                    } else {
                                        textStyle = {};
                                        imageStyle = {};
                                        overlayStyle = {display: 'none'};
                                        style = {};
                                    }
                                    return(
                                        <div className='item_wrapper' data-conflict={`${trait.Conflict}`} key={trait.AssetName}>
                                            <div data-selected='false' style={style} key={i} className={`scroll_box_item ${trait.Active ? 'important-pointer-events active' : ''}`}>
                                                <div className="item_nav_wrapper" style={textStyle}>
                                                    <p data-remaining={`${trait.remainingSupply}`} data-total={`${trait.TotalSupply}`} className="edition">{`${trait.remainingSupply}/${trait.TotalSupply}`}</p>
                                                    <button onClick={(e)=> this.viewItem(e)} onKeyDown={(e)=> this.viewItem(e)} className="k_btn fullscreen_btn">
                                                        <img alt='Fullscreen Icon'  className="fullscreen_icon" src={`/images/fullscreen_icon.png`} />
                                                    </button>
                                                </div>
                                                <img 
                                                // loading="lazy" 
                                                style={imageStyle} 
                                                role="button"  
                                                alt={`${trait.HumanReadableName} Trait Image`}  
                                                onClick={(e)=> this.chooseCartFunc(trait)} 
                                                className={`item_image ${trait.Active ? 'nonGreyScaleImage' : ''}`} 
                                                src={`https://cdn.keepers.com/${trait.AssetName}.webp`} />
                                                <h3 style={textStyle} role="button" data-value={`${trait.AssetName}`} data-type={`${trait.Type}`} data-id={`${trait.ID}`}  onClick={(e)=> this.chooseCartFunc(trait)} className="item_title">{`${trait.HumanReadableName}`}</h3>
                                                <div style={textStyle} role="button"  onClick={(e)=> this.chooseCartFunc(trait)} className="price_wrapper">
                                                    <img alt='Ethereum Icon'  className="eth_icon" src="/images/eth_icon.png" />
                                                    <p className="eth_price">{`${trait.Price} ETH`}</p>
                                                </div>
                                            </div>
                                            <div style={overlayStyle} className={`scroll_box_item_overlay ${trait.Active ? 'active' : ''}`}>
                                                <p className={`scroll_box_item_overlay_message ${trait.Active ? 'active' : ''}`}>Item is not compatible with the current selection or is no longer available</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <Overlay
                        viewItem={this.viewItem}
                        Id={this.state.selectedItemId}
                        Type={this.state.selectedItemType}
                        title={this.state.selectedItemTitle}
                        edition={this.state.selectedItemEdition}
                        totalSupply={this.state.selectedItemTotalSupply}
                        price={this.state.selectedItemPrice}
                        display={this.state.selectedItemDisplay}
                        opacity={this.state.selectedItemOpacity}
                        assetName={this.state.overlayAssetName}
                        addItemtoCart={this.addItemtoCart}
                    />
                </div>
                <Cart
                    showPostProcess={this.props.showPostProcess}
                    display={this.state.cartDisplay}
                    viewCart={this.viewCart}
                    cartObj={this.state.cartObj}
                    cartTotal={this.state.cartTotal}
                    removeCartItem={this.removeCartItem}
                    chooseCartFunc={this.chooseCartFunc}
                    claimKeeper={this.props.claimKeeper}
                />
            </div>
        )
    }
}

export default RightSideUI;