import React from 'react';
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

const paths = [
    'M83 74.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M2 154.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M163 154.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M83 235.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M2 315.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M163 315.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M83 396.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M2 476.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M163 476.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0',
    'M83 557.5a72.5 72.5 0 1 0 145 0a72.5 72.5 0 1 0 -145 0'
]

gsap.registerPlugin(DrawSVGPlugin);
const loadingScreenAnimation = new gsap.timeline({ paused: true })

class LoadingAnimation extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          glowingCirclesRef: React.createRef()
        };
    }
    createLoadingScreenAnimation() {
        // glowingCirclesRef is a reference to the actual rendered paths, I don't know how React handles this.
        var paths = Array.prototype.slice.call(this.state.glowingCirclesRef.current.children); 
      
        // Glowing circles filling in/out
        const loopingAnimation = new gsap.timeline({ repeat: -1 })
      
        loopingAnimation.addLabel('start')
      
        loopingAnimation
          .fromTo(
            paths,
            {
              drawSVG: '0% 0%'
            },
            {
              drawSVG: '0% 100%',
              stagger: 0.1,
              duration: 1,
              ease: 'power1.inOut'
            },
            'start'
          )
          .fromTo(
            paths,
            {
              drawSVG: '0% 100%'
            },
            {
              drawSVG: '100% 100%',
              stagger: 0.1,
              duration: 1,
              ease: 'power1.out'
            },
            '>'
          )
      
        // Master loading animation
        loadingScreenAnimation.addLabel('start')
      
        loadingScreenAnimation
          .set(paths, { drawSVG: '0% 0%' }, 'start')
          .set(
            this.state.glowingCirclesRef.current.childNodes,
            { transformOrigin: 'center', rotation: 90, scaleY: -1 },
            'start'
          )
          .add(loopingAnimation, 'start')
    }
    componentDidMount() {
        this.createLoadingScreenAnimation();
        loadingScreenAnimation.restart();
    }
    render() {
        return(
            <div>
                <svg
                    className="mb-7 w-[126px] overflow-visible lg:mb-10"
                    viewBox="0 0 310 632"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                    <filter
                        id="a"
                        width="360"
                        height="682"
                        x="-25"
                        y="-25"
                        colorInterpolationFilters="sRGB"
                        filterUnits="userSpaceOnUse"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="7" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
                        <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_555_20624"
                        />
                        <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="7" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
                        <feBlend
                        in2="effect1_dropShadow_555_20624"
                        result="effect2_dropShadow_555_20624"
                        />
                        <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_555_20624"
                        result="shape"
                        />
                    </filter>
                    </defs>

                    <g opacity="0.15">
                        {paths.map(path => (
                            <path
                              stroke="white"
                              strokeWidth="4"
                              d={`${path}`}
                              key={path}
                            />
                        ))}
                    </g>

                    <g v-show="state.glowingCirclesVisible">
                    <g ref={this.state.glowingCirclesRef}>
                        {paths.map(path => (
                          <path filter="url(#a)" stroke="white" strokeWidth="4" d={`${path}`} key={path}/>
                        ))}
                    </g>
                    </g>
                </svg>
            </div>
        )
    }
}
export default LoadingAnimation;
