import { ethers } from 'ethers';

export type Trait = {
  id: number;
  compatabilityBitmap: ethers.BigNumber;
};

export type AllConflictsMapping = {
  [traitId: number]: string[]; // mapping from trait id to array of conflicts (0 or 1)
};

// 2D conflict array id a mapping of each traitId
export const getConflictMappingFromTraitData = (traitData: Trait[]) => {
  const conflictMapping: AllConflictsMapping = {};

  for (let trait of traitData) {
    const conflictArray = formatConflictArray(
      ethers.BigNumber.from(trait.compatabilityBitmap)
    );
    conflictMapping[trait.id] = conflictArray;

    if (conflictArray.length !== 256) {
      console.log(
        '⚠️ ERROR: ',
        trait.id,
        'has incorrect bitmap length',
        conflictArray.length
      );
    }
  }

  return conflictMapping;
};

export const areTraitsCompatible = (
  conflicts: AllConflictsMapping,
  traitId1: number,
  traitId2: number
) => {
  return conflicts[traitId1][traitId2] === '1';
};

const formatConflictArray = (conflictBitmap: ethers.BigNumber): string[] => {
  const hex = conflictBitmap
    .toHexString()
    .replace('0x', '')
    .toLowerCase()
    .padStart(64, '0');

  const bin = hex2bin(hex);

  return bin.split('');
};

// convert hex to binary
// taken from https://stackoverflow.com/questions/45053624/convert-hex-to-binary-in-javascript
function hex2bin(hex: string) {
  var out = '';
  for (var c of hex) {
    switch (c) {
      case '0':
        out += '0000';
        break;
      case '1':
        out += '0001';
        break;
      case '2':
        out += '0010';
        break;
      case '3':
        out += '0011';
        break;
      case '4':
        out += '0100';
        break;
      case '5':
        out += '0101';
        break;
      case '6':
        out += '0110';
        break;
      case '7':
        out += '0111';
        break;
      case '8':
        out += '1000';
        break;
      case '9':
        out += '1001';
        break;
      case 'a':
        out += '1010';
        break;
      case 'b':
        out += '1011';
        break;
      case 'c':
        out += '1100';
        break;
      case 'd':
        out += '1101';
        break;
      case 'e':
        out += '1110';
        break;
      case 'f':
        out += '1111';
        break;
      default:
        return '';
    }
  }

  return out;
}
