import React from 'react';
import './App.css';

import { messageMintSite } from './messages';

class LeftSideUI extends React.Component<any,any> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    render() {
        let wa = this.props.mintSiteData.walletAddress;
        let aWalletAdress = wa.substring(0,4) + '...' + wa.substring((wa.length) - 5,wa.length);
        return(
            <div className='leftSideUI-wrapper' style={{display: this.props.loadingStateDisplay}}>
                <div className='wallet-wrapper'>
                    <button onClick={this.props.showExit} className='wallet-button'>
                        <img className='wallet-button-icon' src='/images/wallet_icon.png' />
                    </button>
                    <div className='divider'></div>
                    <div className='wallet-info'>
                        <img className='wallet-profile-icon' src={this.props.mintSiteData.profileImage !== ''? this.props.mintSiteData.profileImage: '/images/profile_icon.png'} />
                        <p className='wallet-account-number'>{`${aWalletAdress}`}</p>
                    </div>
                </div>
                <div className='description-popup-wrapper' onClick={() => this.onDescriptionPopupWrapperClick()}>
                    <button className='ellipse-button above'>
                        <div className='e-logo-div above'></div>
                        {/* <img className='ellipse-image' src='/images/ellipse.png' /> */}
                    </button>
                    <button className='ellipse-button surface'>
                        <div className='e-logo-div surface'></div>
                        {/* <img className='ellipse-image' src='/images/ellipse.png' /> */}
                    </button>
                    <button className='ellipse-button below last'>
                        <div className='e-logo-div below'></div>
                        {/* <img className='ellipse-image' src='/images/ellipse.png' /> */}
                    </button>
                    <div id='description-popup-below' className='description-popup below'>
                        <div className='description-popup-overlay'>
                            <h4>Below</h4>
                            <p>Below focuses on the diverse and extreme environments that served as the crucible of life. These harsh beginnings inspired a wealth of creation. Although we emerged from within, these environments remain filled with mysteries yet to be explored.</p>
                        </div>
                    </div>
                    <div id='description-popup-surface' className='description-popup surface'>
                        <div className='description-popup-overlay'>
                            <h4>Surface</h4>
                            <p>Under the sun's direct gaze, surface represents the vibrant ecosystems where we learned to refine and apply our creativity on the elements to consciously thrive and adapt. From vast grasslands to dense forests, icy tundra to arid deserts, these landscapes have shaped our collective journey just as we have grown to largely shape them.</p>
                        </div>
                    </div>
                    <div id='description-popup-above' className='description-popup above'>
                        <div className='description-popup-overlay'>
                            <h4>Above</h4>
                            <p>Above features elements from our atmosphere into the immeasurable cosmos. Our observations from a distance have shaped our cultures, science, and dreams. As we continue to stretch our reach into this frontier, the heightened exploration will further refine our perspective and ingenuity beyond current understanding.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onDescriptionPopupWrapperClick() {
        messageMintSite('descriptionPopupWrapperClick', {
            info01: 'imfo01 here',
            info02: 'imfo02 here',
            info03: 'imfo03 here'
        })
    }
}

export default LeftSideUI;
