import React from 'react';
import './App.css';

class Overlay extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    componentDidUpdate(prevProps: any): void {
        // only refresh the video when the assetName changes
        if (this.props.assetName !== prevProps.assetName) {
            (document.querySelector('.overlayVideo') as HTMLVideoElement).load();
        }
    }
    
    render(){
        return(
            <div style={{display: `${this.props.display}`}} className="item_overlay">
                <div id="item_overlay_id" style={{opacity: `${this.props.opacity}`}}className="item_overlay_bg">
                    <div className="overlay_nav">
                        <h3 className="overlay_tab_title">{this.props.title}</h3>
                        <button onClick={(e)=>this.props.viewItem(e)} className="k_btn k_btn_styled_3">
                            <img alt=''  className="exit_icon" src="/images/exit_icon.png" />
                        </button>
                    </div>
                    <div className="overlay_nav_2">
                        <h4>{`${this.props.edition}`} REMAINING<br/>/ {`${this.props.totalSupply}`}</h4>
                    </div>
                    <div className='backdropFilterInside'>
                        <video className='overlayVideo' autoPlay loop>
                            <source src={`https://cdn.keepers.com/${this.props.assetName}.webm`} type="video/webm" />
                        </video>
                    </div>
                    <div className='info_wrapper'>
                        {/* <p className="edition_overlay">{this.props.edition}</p>
                        <h3 className="item_title_overlay">{this.props.title}</h3> */}
                        <div className="price_wrapper_overlay">
                            <div className="price_subwrapper_overlay">
                                <div className="price_subflex_overlay">
                                    <img alt=''  className="eth_icon_overlay" src="/images/eth_icon.png" />
                                    <p className="eth_price_overlay">{this.props.price}</p>
                                </div>
                                <div className="price_subflex_overlay _2">
                                    <p className='UE_P'>Cinematic render in</p>
                                    <img className='UE_Img' src='/images/Unreal-Engine-Invert.png' />
                                </div>
                            </div>
                            <button onClick={()=>{this.props.addItemtoCart({
                                ID: this.props.Id, 
                                Price: parseFloat(this.props.price), 
                                AssetName: this.props.assetName, 
                                HumanReadableName: this.props.title,
                                Type: this.props.Type
                            })}}className="try_on_btn">TRY ON</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Overlay;