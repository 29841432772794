import React from 'react';
import './App.css';

class Cart extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }
    
    render(){
        return(
            <div style={{position: 'relative'}}>
                <div style={{display: `${this.props.display}`}} className='cart-item-overlay'>
                    {Object.values(this.props.cartObj).map((el: any) => {
                        Object.values(this.props.cartObj).reduce((total: any,current: any)=> {
                            return total + current.length;
                        },0);
                        return(
                            el.map((trait: any) => {
                                console.log(trait);
                                return(
                                    <div className='cart_item'>
                                        <img role="button"  alt={`${trait.HumanReadableName} Trait Image`} className="item_image_cart" src={`https://cdn.keepers.com/${trait.AssetName}.webp`} />
                                        <h4 className='cart-item-title'>{trait.HumanReadableName}</h4>
                                        <div role="button" className="price_wrapper_cart">
                                            <img alt='Ethereum Icon'  className="eth_icon_cart" src="/images/eth_icon.png" />
                                            <p className="eth_price_cart">{`${trait.Price} ETH`}</p>
                                        </div>
                                        <button onClick={()=>{this.props.chooseCartFunc(trait)}} className="remove_cart_btn">
                                            <img className="remove_cart_item_image" src={`/images/x.png`} />
                                        </button>
                                    </div>
                                )
                            })
                        )
                    })}
                </div>
                <div className='cart-wrapper'>
                    <div className='cart-menu'>
                        <button onClick={this.props.viewCart} className='cart-view-button'>
                            <span className='cart-count'>
                                {Object.values(this.props.cartObj).reduce((total: any,current: any)=> {
                                    return total + current.length;
                                },0)}
                            </span>
                            <img className='cart-icon' src='/images/cart_icon.png' />
                        </button>
                        <div className='cart-price-wrapper'>
                            <h3 className='cart-price-title'>{`${this.props.cartTotal} ETH`}</h3>
                        </div>
                        <button onClick={()=>{this.props.claimKeeper(this.props.cartObj)}}className='cart-mint-button'>CLAIM YOUR KEEPER</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart;