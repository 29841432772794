import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  FC,
} from 'react';
import {
  AllConflictsMapping,
  getConflictMappingFromTraitData,
} from '../TraitConflictHelpers';
import { formatEther } from 'ethers/lib/utils';

import { Keepers } from '../KeepersContract';

export type AllAvailabilitiesMapping = {
  [traitId: number]: number;
};

export type AllPricesMapping = {
  [traitId: number]: number;
};

export interface AvailabilitiesAndConflicts {
  allConflicts: AllConflictsMapping;
  allAvailabilities: AllAvailabilitiesMapping;
  allPrices: AllPricesMapping;
}

const defaultAvailabilitiesAndConflicts: AvailabilitiesAndConflicts = {
  allConflicts: {},
  allAvailabilities: {},
  allPrices: {},
};

export const AvailabilitiesAndConflictsContext =
  createContext<AvailabilitiesAndConflicts>(defaultAvailabilitiesAndConflicts);

// Provides the global availabilities and conflicts to the application
export const AvailabilitiesAndConflictsProvider: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [allAvailabilities, setAllAvailabilities] =
    useState<AllAvailabilitiesMapping>({});
  const [allConflicts, setAllConflicts] = useState<AllConflictsMapping>({});
  const [allPrices, setAllPrices] = useState<AllPricesMapping>({});

  useEffect(() => {
    async function fetchAllTraitsAndAvailabilities() {
      const traitsAndAvailabilities = await Keepers.methods
        .getAllTraitsAndAvailabilities()
        .call();

      let obj: AllAvailabilitiesMapping = {};
      let prices: AllPricesMapping = {};

      traitsAndAvailabilities.forEach((e: any) => {
        obj[e.id] = e.remainingSupply;
        prices[e.id] = parseFloat(formatEther(e.priceWei));
      });

      const conflicts = getConflictMappingFromTraitData(
        traitsAndAvailabilities
      );
      console.log('Fetched Availabilities');
      // TODO: do stuff with the traits and availabilities
      setAllAvailabilities(obj);
      setAllConflicts(conflicts);
      setAllPrices(prices);
    }

    // set up eht interval
    const THIRTY_SECONDS = 30000;
    const interval = setInterval(() => {
      fetchAllTraitsAndAvailabilities();
    }, THIRTY_SECONDS);

    // do the initial fetch
    fetchAllTraitsAndAvailabilities().then(null);

    // clean up side effects
    return () => clearInterval(interval);
  }, []);

  return (
    <AvailabilitiesAndConflictsContext.Provider
      value={{ allConflicts, allAvailabilities, allPrices }}
    >
      {children}
    </AvailabilitiesAndConflictsContext.Provider>
  );
};
