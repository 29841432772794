import React, { useEffect } from 'react';
import './App.css';

class PostProcess extends React.Component<any, any>{
    // constructor(props: any) {
    //     super(props);
    //     this.state = {
    //         imageSrc: `/images/Loading.png`,
    //         heading1: `We're completing \n your transaction...`,
    //         heading2: `Hold tight, this normally take around \n 45 seconds to 2 minutes.`,
    //         heading3: `Don't refresh this page`,
    //         button1: `Go to the avatar Management`,
    //         button2: `Dismiss`,
    //         displayButtons: 'none',
    //         displayHeading3: 'block'
    //     }
    // }

    // componentDidUpdate(): void {
    //     if(this.props.transactionState === 'complete') {
    //         this.setState({
    //             heading1: `Building your Keeper.`,
    //             heading2: `We are creating the high resolution version of your\nKeeper so you can use it wherever you want.\nThis can take 20-30mins, it's worth the wait.`,
    //             displayHeading3: 'none',
    //             displayButtons: 'block'
    //         })
    //     }
    //     if(this.props.transactionState === 'exit') {
    //         this.setState({
    //             heading1: `You're leaving...`,
    //             heading2: `If you leave this page, all your customisations will be lost\nand you will have to start your Keeper again.`,
    //             button1: `Exit configurator`,
    //             button2: `Cancel`,
    //             imageSrc: `/images/generic_keeper.png`
    //         })
    //     }
    // }
    
    render(){
        return(
            <div style={{display: `${this.props.display}`}} className="post_overlay">
                <div className='post-inner_overlay'>
                    <img className='post-inner_image' src={this.props.imageSrc} />
                    <div className='post-inner_content'>
                        <h2>{this.props.heading1}</h2>
                        <h3>{this.props.heading2}</h3>
                        <h4 style={{display: `${this.props.displayHeading3}`}}>{this.props.heading3}</h4>
                    </div>
                    <button onClick={this.props.onExitConfirm} style={{display: `${this.props.displayButtons}`}} className='post-inner_btn1'>{this.props.button1}</button>
                    <button onClick={this.props.exit2PostProcess} style={{display: `${this.props.displayButtons}`}} className='post-inner_btn2'>{this.props.button2}</button>
                </div>
            </div>
        )
    }
}

export default PostProcess;