/**
 * Send a message to the parent window.
 *
 * @param {string} action - The action to send to the parent window
 * @param {Object} data - The additional data to send to the parent window
 */
export function messageMintSite(action, data = {}) {
  window.parent.postMessage({
    origin: 'configurator',
    action,
    ...data
  }, process.env.REACT_APP_MINT_SITE_URL ?? '*')
}

/**
 * Setup a listener for messages from the parent window.
 */
export function setupMessageListener() {
  window.addEventListener('message', (event) => {
    if (event.data.origin !== 'mintSite') return

    switch (event.data.action) {
      case 'mintData':
        console.warn('mintData received', event.data)
        console.warn('Here we receive `walletAddress` and `tokenId`')
        return event.data;
      default:
        console.warn('Unknown action', event.data.action)
        break;
    }
  })
}
