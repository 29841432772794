import { ethers } from 'ethers';

export type Config = {
  head: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  body: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  upper: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  lower: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  shoes: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  suit: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  accessories: [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
};

type Category = keyof Config;

const keys: (keyof Config)[] = [
  'head',
  'body',
  'upper',
  'lower',
  'shoes',
  'suit',
  'accessories',
];

type Trait = {
  traitId: number;
  categoryId: number;
};

export enum ConfigValidity {
  Valid = 0,
  MismatchedCategoryId,
  IncompatibleOrUnavailable,
  ExceedsMaxNumTraits,
  MissingBody,
  MissingSpecialBody,
  AlreadyTaken,
  InvalidTraitId,
}

export const getBitmapFromConfigStruct = (
  configStruct: Config
): ethers.BigNumber => {
  const traitList = getTraitListFromConfigStruct(configStruct);
  const bitmap = packTraitsIntoConfigBitmap(traitList);

  return bitmap;
};

// converts the traits in config format to a list of trait ids with their category
const getTraitListFromConfigStruct = (configStruct: Config): Trait[] => {
  const allTraitIdsWithCategory = [];
  let bodyId = 0;
  for (let key of keys) {
    const traitIdArrayForCategory = configStruct[key];
    for (let i = 0; i < traitIdArrayForCategory.length; i++) {
      if (traitIdArrayForCategory[i] === 0) continue;

      const categoryId = categoryToCategoryId(key);
      const traitId = traitIdArrayForCategory[i];
      allTraitIdsWithCategory.push({
        traitId,
        categoryId,
      });

      if (key === 'body') bodyId = traitId;
    }
  }

  // if no body was supplied, add the default bod
  const BLACK_BODY_ID = 1;
  if (bodyId === 0) {
    allTraitIdsWithCategory.push({
      traitId: BLACK_BODY_ID,
      categoryId: categoryToCategoryId('body'),
    });
  }

  return allTraitIdsWithCategory;
};

// converts the trait list into the bitmap mormat used on the contract
// this format is more gas efficient which is why it is used on the smart contract
const packTraitsIntoConfigBitmap = (traitList: Trait[]) => {
  const hexPackedTraitsWithCategory = [];
  for (let i = 0; i < traitList.length; i++) {
    const traitToPack = traitList[i];
    const traitId = traitToPack.traitId;
    const categoryId = traitToPack.categoryId;
    const bitPackedTrait = traitId | (categoryId << 8);

    const hexStr = bitPackedTrait.toString(16).padStart(3, '0');
    hexPackedTraitsWithCategory.push(hexStr);
  }

  return ethers.BigNumber.from(
    '0x' + hexPackedTraitsWithCategory.join('').padStart(64, '0')
  );
};

const categoryToCategoryId = (category: Category) => {
  switch (category) {
    case 'body':
      return 0;
    case 'head':
      return 1;
    case 'upper':
      return 2;
    case 'lower':
      return 3;
    case 'shoes':
      return 4;
    case 'suit':
      return 5;
    default:
      return 6;
  }
};
