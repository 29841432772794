const BATCH_SIZE = 30;

export const preloadAssets = async (traits: any[]) => {
  let promises = [];
  for (let i = 0; i < traits.length; i++) {
    const trait = traits[i];

    const imgPromise = loadImage(
      `https://cdn.keepers.com/${trait.AssetName}.webp`
    );
    const videoPromise = loadVideo(
      `https://cdn.keepers.com/${trait.AssetName}.webm`
    );

    promises.push(imgPromise);
    promises.push(videoPromise);

    if (promises.length >= BATCH_SIZE || i === traits.length - 1) {
      await Promise.all(promises);
      promises = [];

      // console.log(`Loaded ${i + 1} of ${traits.length} assets`);
    }
  }

  console.log('Finished preloading assets');
};

const loadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const loadVideo = (src: string) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadeddata = () => resolve(video);
    video.onerror = reject;
    video.src = src;
  });
};
